.my-form-create-new-user {
    /* Takes up the entire viewport height */
    height: 90vh;
    /* Centers content vertically and horizontally */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* Additional styles for clarity */
    background-color: #f0f0f0e5;
  }