.font-definition {
    font-family: "Comfortaa", sans-serif;
}

.my-spacer {
  margin-bottom: 100px;
}

/* Enable sticky styles in Tables */
.enable-sticky .rdt_TableHead .rdt_TableCol:first-child,
.enable-sticky .rdt_TableBody .rdt_TableRow .rdt_TableCell:first-child {
  position: sticky;
  left: 0;
  background-color: #ebe9e9;
  color: black; /* Adjust as necessary */
  z-index: 1;
}

/* Disable sticky styles in Tables */
.disable-sticky .rdt_TableHead .rdt_TableCol:first-child,
.disable-sticky .rdt_TableBody .rdt_TableRow .rdt_TableCell:first-child {
  position: static !important;
  background-color: inherit !important;
  color: inherit !important;
  z-index: auto !important;
}


.custom-modal .modal-dialog {
  max-width: 90%; /* Adjust the percentage as needed */
  width: 90%; /* Adjust the percentage as needed */
}


.fixed-width-label {
  display: inline-block;
  min-width: 15ch; /* Exactly 10 characters width */
  white-space: nowrap; /* Prevents text wrapping */
  text-align: left; /* Align the text to the left */
}

.fixed-width-label-pc {
  display: inline-block;
  min-width: 8ch; /* Exactly 10 characters width */
  white-space: nowrap; /* Prevents text wrapping */
  text-align: left; /* Align the text to the left */
}
