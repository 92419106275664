.my-home-page {  
  height: 90vh; /* Full viewport height */
  width: 100%; /* Full width */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('../../../static/background-homepage.png');
  background-size: cover; /* Cover the entire element */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  position: absolute;
}

.custom-badge {
  font-family: "Chakra Petch", 'sans-serif';
  font-size: 2rem !important;
  padding: 4rem 4rem !important; /* Adjust padding */
  border-radius: 1.5rem !important;  /* Adjust border radius for larger badge */
}